import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// mui components
import { Box, Stack, Tab, Tabs } from "@mui/material";

// custom components
import SecondaryButton from "../../components/buttons/secondary-button";
import CustomLayout from "../../components/layout";
import LoanSummaryCard from "../../components/card/loan-summary";
import TabPanel from "../../components/tab-panel";

import LockInfoCard from "../../components/card/lock-info";
import ConditionTrackingCard from "../../components/card/condition-tracking";
import ChangeRequestCard from "../../components/card/change-request";
import DocumentsCard from "../../components/card/documents";
import ImportantDatesCard from "../../components/card/important-dates";
import FileContactCard from "../../components/card/file-contact";
import AppraisalsCard from "../../components/card/appraisals";
import ClosingInformationCard from "../../components/card/closing-information";
import RequestLockCard from "../../components/card/request-lock";
import LoanContactCard from "../../components/card/loan-contact";

import TemporaryDrawer from "../../components/temporary-drawer";

// custom modal
import LoadingDataModal from "../../components/modal/loading-data-modal";
import ProcessingModal from "../../components/modal/processing-modal";
import SuccessModal from "../../components/modal/success-modal";
import ErrorModal from "../../components/modal/error-modal";
import NavigationModal from "../../components/modal/navigation-modal";

// api slice
import { useGetLoanSummaryQuery } from "../../features/loanSummary/loanSummaryApiSlice";
import { useFieldMutation } from "../../features/field-values/fieldValueApiSlice";

// reducer slices
import { selectUserDetails } from "../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../features/admin/impersonated-user/impersonatedUserSlice";

// helper utils
import { LOAN_CHANNEL_CONSTANTS } from "../../utils/common-constants";
import { selectedUserWithLoanChannel } from "../../utils/common-helpers";

// custom styles
import styles from "./index.module.scss";

// custom mui styles
import "./mui-styles.scss";

const LoanSummary = () => {
	const params = useParams();
	const navigate = useNavigate();

	const [tabValue, setTabValue] = useState(false);

	const [selectedCondition, setSelectedCondition] = useState("");

	const [processingText, setProcessingText] = useState("");
	const [successText, setSuccessText] = useState("");

	const [drawerType, setDrawerType] = useState("");
	const [drawerOpen, setDrawerOpen] = useState({});

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const handleOpenDrawer = () => {
		setDrawerOpen({
			right: true,
		});
	};

	const handleCloseDrawer = () => {
		setDrawerOpen({
			right: false,
		});
	};

	const [processingModalVisible, setProcessingModalVisible] = useState(false);

	const handleOpenProcessingModal = () => {
		setProcessingModalVisible(true);
	};

	const handleCloseProcessingModal = () => {
		setProcessingModalVisible(false);
	};

	const [successModalVisible, setSuccessModalVisible] = useState(false);

	const handleOpenSuccessModal = () => {
		setSuccessModalVisible(true);
	};

	const handleCloseSuccessModal = () => {
		setSuccessModalVisible(false);
	};

	const [errorModalText, setErrorModalText] = useState("");
	const [errorModalVisible, setErrorModalVisible] = useState(false);
	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const { data: loanSummaryData, isLoading: loadingLoanData } =
		useGetLoanSummaryQuery(params?.id);

	const [loanData, setLoanData] = useState({});

	useEffect(() => {
		if (loanSummaryData !== undefined) {
			setLoanData(loanSummaryData?.data);
		}
	}, [loanSummaryData]);

	const [loadingDataText, setLoadingDataText] = useState("");
	const [loadingDataModal, setLoadingDataModal] = useState(false);

	const handleOpenLoadingDataModal = () => {
		setLoadingDataModal(true);
	};

	const handleCloseLoadingDataModal = () => {
		setLoadingDataModal(false);
	};

	const [navigationModalText, setNavigationModalText] = useState("");
	const [navigationModalVisible, setNavigationModalVisible] = useState(false);
	const [orgLoanChannel, setOrgLoanChannel] = useState("");

	const [hideLoan, setHideLoan] = useState(false);

	const handleOpenNavigationModal = () => {
		setNavigationModalVisible(true);
	};

	const handleCloseNavigationModal = () => {
		setNavigationModalVisible(false);
	};

	const handleNavigatePipeline = () => {
		handleCloseNavigationModal();
		navigate("/pipeline");
	};

	useEffect(() => {
		if (loadingLoanData) {
			handleOpenLoadingDataModal();
			setLoadingDataText("Setting up the loan details");
		} else {
			handleCloseLoadingDataModal();
		}
	}, [loadingLoanData]);

	useEffect(() => {
		// check if user is from the same organization and loan channel
		console.log("145 user channel:", userDetails?.organization_name);
		console.log("145 org loan channel:", orgLoanChannel);

		if (
			userDetails?.organization_name !== undefined &&
			userDetails?.organization_name !== "" &&
			orgLoanChannel !== undefined &&
			orgLoanChannel !== ""
		) {
			if (userDetails?.organization_name !== orgLoanChannel) {
				console.log("145 organization does not match");
				setNavigationModalText("You do not have access to this loan.");
				handleOpenNavigationModal();
				setHideLoan(true);
			}
		}
	}, [userDetails, orgLoanChannel]);

	const findFieldValue = (fieldData, fieldId) => {
		return fieldData?.find((o) => o.fieldId === fieldId)?.value;
	};

	const updateField = (fieldData) => {
		console.log("160 field values:", fieldData);

		setOrgLoanChannel(findFieldValue(fieldData, "TPO.X14"));
	};

	const [field] = useFieldMutation();

	const [fieldValues, setFieldValues] = useState([]);

	const getFieldValues = async () => {
		try {
			const getValues = await field({
				loan_guid: params?.id,
				fields:
					"2,URLA.X73,URLA.X75,12,14,4000,4001,4002,4004,4005,4006,3,2400,VASUMM.X23,Log.MS.CurrentMilestone,1401,CX.LOCKREQUESTTIMESTAMP,2149,2151,3420,2202,2203,2160,TPO.X14",
			}).unwrap();
			setFieldValues(getValues?.response);
			updateField(getValues?.response);
		} catch (err) {
			// setError(err?.data?.message);
		}
	};

	useEffect(() => {
		getFieldValues();
	}, []);

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleTabChange = (event, value) => {
		setTabValue(value);
	};

	const toggleDrawer = (event, drawerType) => {
		if (drawerType !== undefined && drawerType !== "") {
			setDrawerType(drawerType);
			handleOpenDrawer();
		} else {
			setDrawerType("");
			handleCloseDrawer();
		}
	};

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "Loan Pipeline",
			path: "/pipeline",
		},
		{
			label: !hideLoan && loanData?.loanNumber,
			path: "",
			handleClick: (e) => handleTabChange(e, false),
		},
	];

	const tabs = [
		{
			label: "Lock Information and Activities",
			isDisabled: tabValue !== false && tabValue !== 0,
			content: (
				<LockInfoCard
					loanData={loanData}
					fieldData={fieldValues}
					toggleDrawer={toggleDrawer}
					handleTabChange={handleTabChange}
					// loading data modal
					handleOpenLoadingDataModal={handleOpenLoadingDataModal}
					handleCloseLoadingDataModal={handleCloseLoadingDataModal}
					setLoadingDataText={setLoadingDataText}
					// error modal
					handleOpenErrorModal={handleOpenErrorModal}
					setErrorModalText={setErrorModalText}
				/>
			),
		},
		{
			label: "Condition Tracking",
			isDisabled: tabValue !== false && tabValue !== 1,
			content: (
				<ConditionTrackingCard
					toggleDrawer={toggleDrawer}
					handleTabChange={handleTabChange}
					// loading data modal
					handleOpenLoadingDataModal={handleOpenLoadingDataModal}
					handleCloseLoadingDataModal={handleCloseLoadingDataModal}
					setLoadingDataText={setLoadingDataText}
					// error modal
					handleOpenErrorModal={handleOpenErrorModal}
					setErrorModalText={setErrorModalText}
					// custom functions
					setSelectedCondition={setSelectedCondition}
				/>
			),
		},
		{
			label: "Change Requests",
			isDisabled: tabValue !== false && tabValue !== 2,
			content: (
				<ChangeRequestCard
					toggleDrawer={toggleDrawer}
					handleTabChange={handleTabChange}
					// loading data modal
					handleOpenLoadingDataModal={handleOpenLoadingDataModal}
					handleCloseLoadingDataModal={handleCloseLoadingDataModal}
					// error modal
					handleOpenErrorModal={handleOpenErrorModal}
					setErrorModalText={setErrorModalText}
				/>
			),
		},
		{
			label: "Documents",
			isDisabled: tabValue !== false && tabValue !== 3,
			content: (
				<DocumentsCard
					toggleDrawer={toggleDrawer}
					handleTabChange={handleTabChange}
					// loading data modal
					handleOpenLoadingDataModal={handleOpenLoadingDataModal}
					handleCloseLoadingDataModal={handleCloseLoadingDataModal}
					setLoadingDataText={setLoadingDataText}
				/>
			),
		},
		{
			label: "Important Dates",
			isDisabled: tabValue !== false && tabValue !== 4,
			content: (
				<ImportantDatesCard
					handleTabChange={handleTabChange}
					// loading data modal
					handleOpenLoadingDataModal={handleOpenLoadingDataModal}
					handleCloseLoadingDataModal={handleCloseLoadingDataModal}
					setLoadingDataText={setLoadingDataText}
					// error modal
					handleOpenErrorModal={handleOpenErrorModal}
					setErrorModalText={setErrorModalText}
				/>
			),
		},
		!(
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
		) && {
			label: "Appraisals",
			isDisabled: tabValue !== false && tabValue !== 5,
			content: (
				<AppraisalsCard
					handleTabChange={handleTabChange}
					// loading data modal
					handleOpenLoadingDataModal={handleOpenLoadingDataModal}
					handleCloseLoadingDataModal={handleCloseLoadingDataModal}
					setLoadingDataText={setLoadingDataText}
					// error modal
					handleOpenErrorModal={handleOpenErrorModal}
					setErrorModalText={setErrorModalText}
				/>
			),
		},
		!(
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
		) && {
			label: "Closing Information",
			isDisabled: tabValue !== false && tabValue !== 6,
			content: (
				<ClosingInformationCard
					handleTabChange={handleTabChange}
					toggleDrawer={toggleDrawer}
					// loading data modal
					handleOpenLoadingDataModal={handleOpenLoadingDataModal}
					handleCloseLoadingDataModal={handleCloseLoadingDataModal}
					setLoadingDataText={setLoadingDataText}
					// error modal
					handleOpenErrorModal={handleOpenErrorModal}
					setErrorModalText={setErrorModalText}
				/>
			),
		},
		// {
		// 	label: "File Contact",
		// 	isDisabled: tabValue !== false && tabValue !== 7,
		// 	content: (
		// 		<FileContactCard
		// 			handleTabChange={handleTabChange}
		// 			// loading data modal
		// 			handleOpenLoadingDataModal={handleOpenLoadingDataModal}
		// 			handleCloseLoadingDataModal={handleCloseLoadingDataModal}
		// 			setLoadingDataText={setLoadingDataText}
		// 			// error modal
		// 			handleOpenErrorModal={handleOpenErrorModal}
		// 			setErrorModalText={setErrorModalText}
		// 		/>
		// 	),
		// },
		(selectedUserWithLoanChannel(userDetails, impersonatedUser)
			?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.WHS) && {
			label: "Loan Contacts",
			isDisabled: tabValue !== false && tabValue !== 8,
			content: (
				<LoanContactCard
					handleTabChange={handleTabChange}
					// loading data modal
					handleOpenLoadingDataModal={handleOpenLoadingDataModal}
					handleCloseLoadingDataModal={handleCloseLoadingDataModal}
					setLoadingDataText={setLoadingDataText}
					// error modal
					handleOpenErrorModal={handleOpenErrorModal}
					setErrorModalText={setErrorModalText}
				/>
			),
		},
		// {
		// 	label: "Request lock",
		// 	isDisabled: tabValue !== false && tabValue !== 9,
		// 	content: (
		// 		<RequestLockCard
		// 			loanData={loanData}
		// 			handleTabChange={handleTabChange}
		// 			getFieldValuesOnLoanSummary={getFieldValues}
		// 		/>
		// 	),
		// },
	].filter(Boolean); // Remove any `false` values when a tab is conditional

	console.log("420 tab value:", tabValue);

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				{!hideLoan && (
					<Stack
						direction="column"
						className={styles.stackContainer}
						sx={{
							overflow: "hidden",
						}}
					>
						<LoanSummaryCard loanData={loanData} fieldData={fieldValues} />

						<Stack direction="column" className={styles.bodyContainer}>
							<Stack
								direction="row"
								className={`${styles.tabsContainer} tabStackContainer`}
							>
								{
									(console.log("290 tabs:", tabs),
									console.log("290 tab value:", tabValue))
								}
								<Tabs
									value={tabValue}
									onChange={handleTabChange}
									className={styles.tabContainer}
									TabIndicatorProps={{ style: { display: "none" } }}
								>
									{tabs.map((tab, index) => (
										<Tab
											key={index}
											className={styles.tabBtnContainer}
											component="div"
											label={
												<SecondaryButton
													text={tab.label}
													extraClass={
														tabValue === index
															? styles.selectedTabBtn
															: styles.tabBtn
													}
													disabled={tab.isDisabled}
												/>
											}
											disabled={tab.isDisabled}
											{...a11yProps(index)}
										/>
									))}
								</Tabs>
							</Stack>

							<Stack direction="column" className={styles.tabPanelContainer}>
								{tabs.map((tab, index) => (
									<TabPanel key={index} value={tabValue} index={index}>
										{tab.content}
									</TabPanel>
								))}

								<TabPanel value={tabValue} index={9}>
									<RequestLockCard
										loanData={loanData}
										handleTabChange={handleTabChange}
										getFieldValuesOnLoanSummary={getFieldValues}
									/>
								</TabPanel>
							</Stack>
						</Stack>
					</Stack>
				)}

				<TemporaryDrawer
					// drawer props
					drawerOpen={drawerOpen}
					drawerDirection="right"
					drawerType={drawerType}
					toggleDrawer={toggleDrawer}
					handleCloseDrawer={handleCloseDrawer}
					handleOpenProcessingModal={handleOpenProcessingModal}
					handleCloseProcessingModal={handleCloseProcessingModal}
					setProcessingText={setProcessingText}
					handleOpenSuccessModal={handleOpenSuccessModal}
					setSuccessText={setSuccessText}
					handleOpenErrorModal={handleOpenErrorModal}
					setErrorText={setErrorModalText}
					// request lock props
					loanData={loanData}
					fieldData={fieldValues}
					// condition tracking props
					selectedCondition={selectedCondition}
					setSelectedCondition={setSelectedCondition}
				/>
			</CustomLayout>

			{/* modals */}
			<LoadingDataModal open={loadingDataModal} text={loadingDataText} />
			<ProcessingModal open={processingModalVisible} text={processingText} />
			<SuccessModal
				open={successModalVisible}
				handleClose={handleCloseSuccessModal}
				text={successText}
			/>
			<ErrorModal
				open={errorModalVisible}
				handleClose={handleCloseErrorModal}
				text={errorModalText}
			/>
			<NavigationModal
				open={navigationModalVisible}
				text={navigationModalText}
				confirmText="Okay"
				handleConfirm={handleNavigatePipeline}
			/>
		</Box>
	);
};

export default LoanSummary;
